<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">
                <el-form-item prop="">
                    <el-input clearable @keydown.enter.native="searchName" v-model="searchtitle"
                        placeholder="请输入岗位名称" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click.stop="searchName">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:position:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加岗位
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->


                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border> -->

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable">

            <!-- <el-table-column show-overflow-tooltip    prop="ID" label="ID" align="left" /> -->

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="name" label="岗位名称" align="center" width="150" />

            <el-table-column show-overflow-tooltip prop="state" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    <!-- <el-switch v-model="scope.row.state" active-color="#5BD995" active-text="正常"
                        inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch> -->

                    <el-switch v-model="scope.row.status" :active-value="1" active-color="#5BD995" active-text="正常"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox"
                        style="pointer-events: painted;" @change="switchStatus(scope.row)">
                    </el-switch>

                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="sort" label="排序" align="left" min-width="70" />




            <el-table-column show-overflow-tooltip prop="create_realname" label="创建人" align="left" width="150" />
            <!-- <el-table-column show-overflow-tooltip prop="create_time" label="创建时间" align="left" width="150" /> -->
            <el-table-column show-overflow-tooltip prop="create_time" label="创建时间" align="left" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.create_time!=0">{{ timestampToTime(scope.row.create_time,2) }}</span>
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="update_realname" label="更新人" align="left" width="150" />
            <!-- <el-table-column show-overflow-tooltip prop="update_time" label="更新时间" align="left" width="150" /> -->
            <el-table-column show-overflow-tooltip prop="update_time" label="更新时间" align="left" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.update_time!=0">{{ timestampToTime(scope.row.update_time,2) }}</span>
                </template>
            </el-table-column>





            <el-table-column show-overflow-tooltip label="操作"
                :width="autoTableWidth(['sys:position:edit','sys:position:drop'],75)" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <!-- <el-button @click="view(scope.row)">
                            查看
                        </el-button> -->
                        <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:position:edit')">
                            编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:position:drop')">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>


        </el-table>
        <!-- </Fanfu-Table> -->


        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />



        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />



    </div>
</template>

<script>
    import Search from './components/StationListSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                searchtitle: ""

            }
        },


        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList()
        },

        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.searchName()

                }

            }
        },
        methods: {

            getList() {

                this.$http.get('/api/position', {
                    name: this.searchtitle,
                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code == 200) {
                        // this.setMedicalWasteItem(res.data.results)
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.MedicalWasteItem);

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            searchName() {
                this.data.page = 1
                this.getList()

            },


            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             console.log("++++++++++++++++++++++++++++++++")
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },




            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)

                this.$refs.searchRef.edit(row)

            },


            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api/position/setStatus//' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },


            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';


                            this.$http.del('/api/position/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });




                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });

            },



            toSearch() {
                this.$refs.searchRef.open()
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }

                });


            },



            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
            },
        }

    }
</script>















<style lang="scss" scoped></style>