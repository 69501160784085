<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑岗位':'新增岗位'" :visible.sync="show" width="500px"
        class="selfInputBox">

        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="岗位名称" prop="">
                <el-input v-model="positionInfo.name" />
            </el-form-item>

            <el-form-item label="状态" prop="">
                <el-switch v-model="positionInfo.status" :active-value="1" active-color="#5BD995" active-text="是"
                    :inactive-value="0" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <el-form-item label="顺序" prop="">
                <el-input v-model="positionInfo.sort" />
            </el-form-item>

        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,


                // 回显图片的路径
                photoList: [{
                    url: ""
                }],
                // 图片的路径
                dialogImageUrl: "",


                positionInfo: {
                    name: "",
                    status: 1,
                    sort: ""
                },


                isEdit: false

            }
        },


        watch: {

            show(value) {
                if (this.show) {


                } else {

                    this.isEdit = false

                    this.positionInfo = {
                        name: "",
                        status: 1,
                        sort: ""
                    }

                }
            },

        },

        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            edit(row) {

                this.isEdit = true

                this.formShow = true
                this.show = true

                this.positionInfo = {
                    ...row
                }

            },

            save() {

                if (this.isEdit) {

                    this.$http.put('/api/position/' + this.positionInfo.id, this.positionInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }

                        if (res.code == 200) {
                            this.close()
                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.$http.post('/api/position', this.positionInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }

                        if (res.code == 200) {

                            this.close()

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            },

            close() {
                this.isEdit = false
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)

                this.$parent.getList()
            },



            uploadSectionFile(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("111111" + file.url);
            },



            beforeRemove(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("22222" + file.url);
            },

            handlePreview(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("33333" + file.url);
            },




        }
    }
</script>